<template>
  <div class="user">
    <div class="line_one">
      <el-form inline :model="queryInfo" label-width="80px" ref="form">
        <el-form-item label="星名帖:">
          <el-radio-group v-model="queryInfo.startCard">
            <el-radio :label="null">不限</el-radio>
            <el-radio :label="'有'">有</el-radio>
            <el-radio :label="'无'">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="queryInfo.mobile" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="queryInfo.userName" placeholder="请输入姓名" clearable />
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" @click="search()">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="line_two">
      <el-table
        :data="dataUserList"
        style="width: 100%"
        class="data_list"
        v-loading="loading"
        :header-cell-style="getRowClass"
      >
        <el-table-column label="序号" type="index" align="center" width="80" />
        <el-table-column label="头像" width="150" align="center">
          <template v-slot="{ row }">
            <div class="flex">
              <el-popover placement="right-start" width="250" trigger="click">
                <div class="hover_item">
                  <div class="headImg">
                    <img :src="row.headPortrait" />
                  </div>
                  <div class="user_tip">
                    <div class="user_name">
                      <div class="black_one">{{ row.userName }}</div>
                      <div class="icon_man">
                        <img
                          src="../../../../static/images/jr-icon-man.png"
                          v-if="row.gender == 2"
                        />
                        <img
                          src="../../../../static/images/jr-icon-female.png"
                          v-if="row.gender == 1"
                        />
                      </div>
                    </div>
                    <div class="tip">关注的“X+AI”领域: {{ detailList.label }}</div>
                    <div class="tip">加入时间: {{ detailList.joinTime }}</div>
                    <div class="tip">登录次数: {{ detailList.loginCount }}</div>
                    <div class="tip">所在行业: {{ detailList.industry }}</div>
                  </div>
                </div>
                <img
                  :src="row.headPortrait"
                  slot="reference"
                  @click="selectPart(row.id)"
                  style="width: 45px; height: 45px"
                />
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center" width="80" />
        <el-table-column label="手机号" prop="mobile" align="center" width="150" />
        <el-table-column label="机构" prop="institution" align="center" width="100" />
        <el-table-column label="职位" prop="position" align="center" width="150" />
        <el-table-column label="标签" align="center" width="200">
          <template v-slot="{ row }">
            <div class="tip_two">
              <div v-for="(item, index) in row.labelList" :key="index">{{ item }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员等级/积分" prop="phone" align="center" width="150">
          <template v-slot="{ row }">
            <div>{{ row.whetherMember == 0 ? '普通用户' : '会员用户' }}/{{ row.integral }}</div>
          </template>
        </el-table-column>
        <el-table-column label="是否展示在首页" prop="phone" align="center" width="130">
          <template v-slot="{ row }">
            <el-tooltip placement="top" content="启用或关闭">
              <el-switch
                @change="changeStatus(row)"
                v-model="row.isShow"
                active-color="#4DA75B"
                inactive-color="#C4C4C4"
                active-text="是"
                inactive-text="否"
                :active-value="2"
                :inactive-value="1"
                :width="48"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="想联系" align="center" width="120" sortable prop="beContactCount">
          <template v-slot="{ row }">
            <div class="blue" @click="goContact(row)">
              {{ row.beContactCount > 999 ? '999+' : row.beContactCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员有效时间" prop="deadline" align="center" width="120" />
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="blue" @click="goDetail(row)">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { pagination } from '@/mixins/pagination'
import { getAllMember, selectPart, updateCard } from '@/api/userManagement.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 0,
  pageSize: 10,
  startCard: null
})
export default {
  name: 'User',
  mixins: [pagination],
  data() {
    return {
      dataUserList: [],
      selectionArr: [],
      detailList: [],
      loading: false,
      dataList: {},
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.search()
  },

  methods: {
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10
      }
      this.search()
    },
    async changeStatus(row) {
      // console.log(row.isShow);
      await updateCard({ id: row.id })
      this.$message.success('修改成功')
      this.search()
    },
    goDetail(row) {
      this.$router.push({
        path: '/userManagement/userDetail?id=' + row.id
      })
    },
    goContact(row) {
      this.$router.push({
        path: `/VIPManagement/userContact?id=${row.id}`
      })
    },
    async selectPart(id) {
      const { data: res } = await selectPart({ id: id })
      if (res.resultCode === 200) {
        this.detailList = res.data
      }
    },
    async search() {
      this.loading = true
      const { data: res } = await getAllMember(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
      // console.log(this.dataUserList);
    },
    closeDialog() {
      this.AddFlag = false
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_one {
    background: #ffffff;
    border-radius: 12px;
    padding-top: 20px;
  }
  .line_two {
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .tip_two {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 8px;
      div {
        padding: 2px 5px;
        margin-top: 5px;
        font-size: 12px;
        line-height: 17px;
        color: #7067f2;
        background: #7067f21a;
        margin-right: 8px;
      }
    }
  }
  .stage {
    margin: 0px auto;
    width: 64px;
    height: 24px;
    background: #fbe2b7;
    color: #eda00a;
    border-radius: 16px;
  }
  .blue {
    margin-top: 3px;
    color: #3562ff;
    cursor: pointer;
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }

  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type='number'] {
    -moz-appearance: textfield;
  }
}
/deep/.el-switch__label {
  span {
    font-size: 10px;
  }
}
// /deep/.el-switch__core::after {
//   width: 10px;
//   height: 10px;
//   top: 4px;
//   left: 5px;
// }
</style>
