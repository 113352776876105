var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"line_one"},[_c('el-form',{ref:"form",attrs:{"inline":"","model":_vm.queryInfo,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"星名帖:"}},[_c('el-radio-group',{model:{value:(_vm.queryInfo.startCard),callback:function ($$v) {_vm.$set(_vm.queryInfo, "startCard", $$v)},expression:"queryInfo.startCard"}},[_c('el-radio',{attrs:{"label":null}},[_vm._v("不限")]),_c('el-radio',{attrs:{"label":'有'}},[_vm._v("有")]),_c('el-radio',{attrs:{"label":'无'}},[_vm._v("无")])],1)],1),_c('el-form-item',{attrs:{"label":"手机号:"}},[_c('el-input',{attrs:{"placeholder":"请输入手机号","clearable":""},model:{value:(_vm.queryInfo.mobile),callback:function ($$v) {_vm.$set(_vm.queryInfo, "mobile", $$v)},expression:"queryInfo.mobile"}})],1),_c('el-form-item',{attrs:{"label":"姓名:"}},[_c('el-input',{attrs:{"placeholder":"请输入姓名","clearable":""},model:{value:(_vm.queryInfo.userName),callback:function ($$v) {_vm.$set(_vm.queryInfo, "userName", $$v)},expression:"queryInfo.userName"}})],1),_c('el-form-item',[_c('el-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1),_c('el-form-item',[_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")])],1)],1)],1),_c('div',{staticClass:"line_two"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"data_list",staticStyle:{"width":"100%"},attrs:{"data":_vm.dataUserList,"header-cell-style":_vm.getRowClass}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"头像","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex"},[_c('el-popover',{attrs:{"placement":"right-start","width":"250","trigger":"click"}},[_c('div',{staticClass:"hover_item"},[_c('div',{staticClass:"headImg"},[_c('img',{attrs:{"src":row.headPortrait}})]),_c('div',{staticClass:"user_tip"},[_c('div',{staticClass:"user_name"},[_c('div',{staticClass:"black_one"},[_vm._v(_vm._s(row.userName))]),_c('div',{staticClass:"icon_man"},[(row.gender == 2)?_c('img',{attrs:{"src":require("../../../../static/images/jr-icon-man.png")}}):_vm._e(),(row.gender == 1)?_c('img',{attrs:{"src":require("../../../../static/images/jr-icon-female.png")}}):_vm._e()])]),_c('div',{staticClass:"tip"},[_vm._v("关注的“X+AI”领域: "+_vm._s(_vm.detailList.label))]),_c('div',{staticClass:"tip"},[_vm._v("加入时间: "+_vm._s(_vm.detailList.joinTime))]),_c('div',{staticClass:"tip"},[_vm._v("登录次数: "+_vm._s(_vm.detailList.loginCount))]),_c('div',{staticClass:"tip"},[_vm._v("所在行业: "+_vm._s(_vm.detailList.industry))])])]),_c('img',{staticStyle:{"width":"45px","height":"45px"},attrs:{"slot":"reference","src":row.headPortrait},on:{"click":function($event){return _vm.selectPart(row.id)}},slot:"reference"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":"真实姓名","prop":"userName","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"mobile","align":"center","width":"150"}}),_c('el-table-column',{attrs:{"label":"机构","prop":"institution","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"label":"职位","prop":"position","align":"center","width":"150"}}),_c('el-table-column',{attrs:{"label":"标签","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"tip_two"},_vm._l((row.labelList),function(item,index){return _c('div',{key:index},[_vm._v(_vm._s(item))])}),0)]}}])}),_c('el-table-column',{attrs:{"label":"会员等级/积分","prop":"phone","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.whetherMember == 0 ? '普通用户' : '会员用户')+"/"+_vm._s(row.integral))])]}}])}),_c('el-table-column',{attrs:{"label":"是否展示在首页","prop":"phone","align":"center","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"placement":"top","content":"启用或关闭"}},[_c('el-switch',{attrs:{"active-color":"#4DA75B","inactive-color":"#C4C4C4","active-text":"是","inactive-text":"否","active-value":2,"inactive-value":1,"width":48},on:{"change":function($event){return _vm.changeStatus(row)}},model:{value:(row.isShow),callback:function ($$v) {_vm.$set(row, "isShow", $$v)},expression:"row.isShow"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"想联系","align":"center","width":"120","sortable":"","prop":"beContactCount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"blue",on:{"click":function($event){return _vm.goContact(row)}}},[_vm._v(" "+_vm._s(row.beContactCount > 999 ? '999+' : row.beContactCount)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"会员有效时间","prop":"deadline","align":"center","width":"120"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"blue",on:{"click":function($event){return _vm.goDetail(row)}}},[_vm._v("查看详情")])]}}])})],1)],1),_c('div',{staticClass:"new_page"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }